<template>
  <div class="NewSection">
    <div class="Grid-row">
      <div class="Grid-column-8">
        <span>Name</span>
        <input
          v-model="name"
          :class="{ 'has-error': name_error }"
          class="input"
          type="text"
        />
      </div>
      <div class="Grid-column-4">
        <span>Visible Elements</span>
        <input
          v-model="visibleElements"
          :class="{ 'has-error': visibleElements_error }"
          class="input"
          type="number"
          min="0"
          @input="isPositive"
        />
      </div>
    </div>
    <div class="Grid-row-no-padding">
      <span>Description</span>
      <textarea
        v-model="description"
        :class="{ 'has-error': description_error }"
        class="input input-textarea"
      />
    </div>
    <div class="Grid-row">
      <button
        v-if="ifIndex"
        class="button button-success"
        @click="updateSection"
      >
        Save Section
      </button>
      <button v-else class="button button-success" @click="addSection">
        Add Section
      </button>
      <button class="button button-danger" @click="cancel">Cancel</button>
    </div>
  </div>
</template>

<script>
import { isNonNegativeInteger } from '@/utils/number.helper';

export default {
  name: 'SectionDetails',
  data() {
    return {
      name: null,
      name_error: false,
      description: null,
      description_error: false,
      visibleElements: null,
      visibleElements_error: false,
    };
  },
  computed: {
    ifIndex() {
      return isNonNegativeInteger(this.sectionIndex);
    },
    sectionIndex() {
      return this.$route.params.sectionIndex;
    },
    section() {
      return this.$store.getters.section({ sectionIndex: this.sectionIndex });
    },
  },
  watch: {
    section(newSection, oldSection) {
      if (!oldSection && newSection) {
        this.populateForm(newSection);
      }
    },
  },
  mounted() {
    if (this.section) {
      this.populateForm(this.section);
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'sections' });
    },
    isPositive() {
      if (parseInt(this.visibleElements) < 0) {
        this.visibleElements = '0';
      }
    },
    updateSection() {
      if (this.name && this.description && this.visibleElements) {
        this.$store.dispatch('updateSection', {
          sectionIndex: this.$route.params.sectionIndex,
          name: this.name,
          description: this.description,
          visibleElements: this.visibleElements,
        });
        this.$router.push({ name: 'sections' });
      } else {
        this.handleErrors();
      }
    },
    addSection() {
      if (this.name && this.description && this.visibleElements) {
        this.$store.dispatch('addSection', {
          name: this.name,
          description: this.description,
          visibleElements: this.visibleElements,
        });
        this.$router.push({ name: 'sections' });
      } else {
        this.handleErrors();
      }
    },
    handleErrors() {
      if (!this.name) {
        this.name_error = true;
      }
      if (!this.description) {
        this.description_error = true;
      }
      if (!this.visibleElements) {
        this.visibleElements_error = true;
      }
    },
    populateForm(section) {
      this.name = section.name;
      this.description = section.description;
      this.visibleElements = section.visibleElements;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/sections/NewSection.scss';
</style>
